$primary: #006168;
$secondary: #578873;

$call_to_action_bg: #e9550f;


/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

.static-header .container .search-form {
  background: $secondary;
}

#sidebar-first #block-helper-search-search-holiday-homes,
#search-form-wrapper .search .loading {
  background: $secondary;
}
#search-form-wrapper .search .loading .throbber:after {
  border-left-color: $primary;
  border-bottom-color: $primary;
}


.offers, .offer.search {
  .btn-secondary {
    border-color: $call_to_action_bg;

    background-color: $call_to_action_bg;

    &:hover {
      border-color: #739837;

      color: #fff;

      background-color: #739837;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($call_to_action_bg, 50%);
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem lighten($call_to_action_bg, 50%);
  }
  .btn-secondary:not(:disabled):not(.disabled):active {
    border-color: #739837;

    color: #fff;

    background-color: #739837;
  }
}
